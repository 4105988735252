<template>
  <div class="aa">
    <div class="daohangtitle"></div>
    <div class="mainbox">
      <div class="sur">当前申请概况</div>
      <div class="sura">
        <div class="card-row" v-for="item in labList" :key="item.id">
          <el-card class="box-card card-item" >
            <template #header>
              <div class="card-header">
                <h4>{{item.intent}}</h4>
              </div>
            </template>
            <div style="font-size: 8px;margin: 5px;">&nbsp;&nbsp;{{item.deviceList}}</div>
            <div style="font-size: 8px;margin: 5px;"><span style="font-weight: bold;font-size: 8px">实验开始时间:</span>{{item.startTime}}</div>
            <div style="font-size: 8px;margin: 5px;width: 100%;float: right">

              <el-row v-if="item.use > -2">
                <el-col v-if="item.use == 1 || item.use == 0" :offset="14" :span="4"><el-button plain type="primary" size="small" @click="uploadReportFiles(item.id + '')">上传实验报告</el-button></el-col>
                <el-col v-if="item.use == 1 || item.use == 0" :offset="4" :span="2"><el-button plain size="small" @click="viewDetails(item.id + '')">查 看</el-button></el-col>
                <el-col v-if="item.use != 1 && item.use != 0" :offset="22" :span="2"><el-button plain size="small" @click="viewDetails(item.id + '')">查 看</el-button></el-col>
              </el-row>
              <el-row v-if="item.use == -2 || item.use == -3">
                <el-col :offset="20" :span="4"><el-button plain type="primary" size="small" @click="uploadFiles(item.id + '')">上传附件</el-button></el-col>
              </el-row>
            </div>
          </el-card>

        </div>

      </div>
    </div>
  </div>

  <el-dialog
          v-model="testResultVisible"
          title="上传实验报告"
          width="55%"
          :before-close="handletestResultDialogClose"
  >
    <div style="height:300px;overflow-x: hidden;overflow-y: auto;">
      <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :on-preview="handleReportPreview"
              :before-remove="beforeReportRemove"
              :on-success="uploadReportSuccess"
              :before-upload="beforeReportAvatarUpload"
              name="file"
              :limit="6"
              :data="uploadReportParams"
              :on-exceed="handleReportExceed"
              :file-list="reportFileList">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="text_cls">（只能上传Word、Excel、PPT、PDF文件，且不超过30Mb）</div>
      </el-upload>
    </div>
  </el-dialog>

  <el-dialog
          v-model="dialogViewVisible"
          :title="dialogTitle"
          width="55%"
          :before-close="handleDialogClose"
  >
    <div style="height:400px;overflow-x: hidden;overflow-y: auto;">
      <label class="text_cls">进入实验室目的:</label>
      <el-input
              type="textarea"
              autosize
              :disabled="true"
              placeholder=""
              v-model="viewIntent">
      </el-input>
      <div style="margin-top: 20px;">

        <label class="text_cls">参与人员:</label>
        <div style="width: 100%;padding: 5px; clear: both;">
          <div v-for="(item, index) in viewPersonList" style="margin-left: 5px;float: left">
            <el-tag size="small" >{{item.username}}</el-tag>
          </div>
        </div>

      </div>

      <div style="width: 100%;clear: left;margin-top: 20px">
        <label class="text_cls">实验日期:</label>
        <el-input
                autosize
                :disabled="true"
                placeholder=""
                v-model="viewStartTime">
        </el-input>
      </div>

      <div style="margin-top: 20px">
        <label class="text_cls">设备描述:</label>
        <el-input
                type="textarea"
                autosize
                :disabled="true"
                placeholder=""
                v-model="viewDeviceList">
        </el-input>
      </div>

      <div style="margin-top: 20px">
        <label class="text_cls">设备图片:</label>
        <div style="margin-top: 10px;">
          <label v-for="(item, index) in viewImageList" style="margin-left: 20px;">
          <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :initial-index="index"
                  :preview-src-list="viewImageList">
          </el-image>
          </label>
        </div>
      </div>

      <div style="margin-top: 20px;margin-bottom: 20px;" v-show="isUploadFileDialog">
        <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :on-preview="handlePreview"
                :before-remove="beforeRemove"
                :on-success="uploadSuccess"
                :before-upload="beforeAvatarUpload"
                name="file"
                :limit="6"
                :data="uploadParams"
                :on-exceed="handleExceed"
                :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="text_cls">（只能上传Word、Excel、PPT、PDF文件，且不超过30Mb）</div>
        </el-upload>

      </div>

    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogViewVisible = false">
          返回
        </el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data(){
    return {
      intent: '',
      deviceList: '',
      startTime: '',
      id: -1,
      use: 0,

      labList: [],

      dialogViewVisible: false,
      isUploadFileDialog: true,
      dialogTitle: '查看',

      viewIntent: '',
      viewStartTime: '',
      viewDeviceList: '',
      viewImageList: [],
      viewPersonList: [],
      dirname: '',

      fileList:[],
      uploadUrl:"",
      uploadParams:{},

      testResultVisible: false,
      uploadReportUrl:"",
      uploadReportParams:{},
      reportFileList:[],
    }
  },
  methods:{
    loadTheData(){
      this.$request.doPost('/pc/getLabInfo').then(resp =>{
        if(resp.code == 20000)
        {
          let rlist = resp.data
          this.labList = rlist
        }else{
          ElMessage.error(resp.msg)
        }
      })
    },
    viewDetails(id){
      this.dialogTitle = "查看";
      this.isUploadFileDialog = false;
      this.dialogViewVisible = true;
      this.loadDetails(id)
    },
    handleDialogClose()
    {
      this.dialogViewVisible = false;
    },
    handlePreview(file) {
      let filename = file.name;
      let params = {'labId': this.id, 'filename': filename}
      this.$request.doPost('/pc/downloadFile', params, {responseType: 'blob'}).then(resp =>{
        // 创建a标签
        let a = document.createElement("a");
        a.download = filename;
        // 创建二进制对象
        const blob = new Blob([resp]);
        const downloadURL = (window.URL || window.webkitURL).createObjectURL(blob);
        a.href = downloadURL;

        // 模拟点击
        a.click();

        //释放资源并删除创建的a标签
        URL.revokeObjectURL(downloadURL);// a.href
        if(document.body.contains(a))
        {
          document.body.removeChild(a);
        }
      });
    },
    handleReportPreview(file){
      let filename = file.name;
      let params = {'labId': this.id, 'filename': filename, 'type':"result"}
      this.$request.doPost('/pc/downloadFile', params, {responseType: 'blob'}).then(resp =>{
        // 创建a标签
        let a = document.createElement("a");
        a.download = filename;
        // 创建二进制对象
        const blob = new Blob([resp]);
        const downloadURL = (window.URL || window.webkitURL).createObjectURL(blob);
        a.href = downloadURL;

        // 模拟点击
        a.click();

        //释放资源并删除创建的a标签
        URL.revokeObjectURL(downloadURL);// a.href
        if(document.body.contains(a))
        {
          document.body.removeChild(a);
        }
      });
    },
    beforeRemove(file){
      let params = {"filename": file.name, "labId": this.id}
      this.$request.doPost("/pc/deleteFile", params).then(resp =>{
        if(resp.code != 20000)
        {
          this.$message.error(resp.msg);
        }
      })
      return true
    },
    beforeReportRemove(file){
      let params = {"filename": file.name, "labId": this.id, "type": "result"}
      this.$request.doPost("/pc/deleteFile", params).then(resp =>{
        if(resp.code != 20000)
        {
          this.$message.error(resp.msg);
        }
      })
      return true
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 6 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleReportExceed(files, fileList){
      this.$message.warning(`当前限制选择 6 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    uploadFiles(id){
      this.dialogTitle = "查看（附件操作）";
      this.isUploadFileDialog = true;
      this.dialogViewVisible = true;
      this.loadDetails(id)
    },
    loadDetails(id){
      let params = {'labId': id}
      this.$request.doPost("/pc/getLabInfoDetails", params).then(resp =>{
        if(resp.code == 20000)
        {
          let resData = resp.data
          this.viewIntent = resData['intent']
          this.viewStartTime = resData['startTime']
          this.viewDeviceList = resData['deviceList']
          this.dirname = resData['dirname']
          let imgNameList = resData['imgNameList']
          this.viewImageList = []
          if(imgNameList != null && 0 != imgNameList.length)
          {
            for(let i=0;i<imgNameList.length;++i)
            {
              let imgUrl = this.$global.baseUrl + "/labRecord/getImage?dirname=" + this.dirname + "&filename=" + imgNameList[i]
              this.viewImageList.push(imgUrl)
            }
          }
          this.viewPersonList = []
          let personList = resData['personList']
          if(personList != null && 0 != personList.length)
          {
            for(let i=0;i<personList.length;++i)
            {
              this.viewPersonList.push(personList[i])
            }
          }
          this.fileList = []
          let fileList = resData['fileList']
          if(fileList != null && 0 != fileList.length)
          {
            for(let i=0;i<fileList.length;++i)
            {
              let fileData = {'name': fileList[i]}
              this.fileList.push(fileData)
            }
          }
        }else{
          this.$message.error(resp.msg);
        }
      })
    },
    loadUploadResultFiles(id){
      let params = {'labId': id}
      this.$request.doPost("/pc/getUploadResultList", params).then(resp => {
        if(resp.code == 20000)
        {
          this.uploadReportParams['id'] = id;
          this.id = id;
          let fileList = resp.data;
          this.reportFileList = []
          if(0 != fileList.length)
          {
            for(let i=0;i<fileList.length;++i)
            {
              this.reportFileList.push({'name': fileList[i]})
            }
          }
        }else{
          this.$message.error(resp.msg);
        }
      })
    },
    uploadSuccess(response, file, fileList){
      if(response.code == 20000)
      {
        let filename = response.data.filename
        file.name = filename
      }else{
        this.$message.error(response.msg);
      }
    },
    uploadReportSuccess(response, file, fileList){
      if(response.code == 20000)
      {
        let filename = response.data.filename
        file.name = filename
      }else{
        this.$message.error(response.msg);
      }
    },
    beforeAvatarUpload(file){
      if(file.type.indexOf('word') == -1 && file.type.indexOf("pdf") == -1 &&
              file.type.indexOf('excel') == -1 && file.type.indexOf("sheet") == -1 &&
              file.type.indexOf('powerpoint') == -1 && file.type.indexOf("presentationml" == -1)
      ){
        this.$message.error('只能上传Word、Excel、PPT、PDF文件!');
        return false
      }
      let filesize = file.size / 1024 / 1024;
      if(filesize > 30)
      {
        this.$message.error('上传文件不能超过30Mb!');
        return false
      }

      return true
    },
    beforeReportAvatarUpload(file){
      if(file.type.indexOf('word') == -1 && file.type.indexOf("pdf") == -1 &&
              file.type.indexOf('excel') == -1 && file.type.indexOf("sheet") == -1 &&
              file.type.indexOf('powerpoint') == -1 && file.type.indexOf("presentationml" == -1)
      ){
        this.$message.error('只能上传Word、Excel、PPT、PDF文件!');
        return false
      }
      let filesize = file.size / 1024 / 1024;
      if(filesize > 30)
      {
        this.$message.error('上传文件不能超过30Mb!');
        return false
      }

      return true
    },

    uploadReportFiles(id){
      this.testResultVisible = true;
      this.loadUploadResultFiles(id);
    },

    handletestResultDialogClose()
    {
      this.testResultVisible = false;
    },
  },
  mounted(){
    let uploadParams = {}
    uploadParams['token'] = this.$store.state.token
    this.uploadParams = uploadParams
    let uploadReportParams = {'token': this.$store.state.token, 'type': 'result'}
    this.uploadReportParams = uploadReportParams
    let uploadUrl = this.$global.baseUrl + "/pc/uploadFile";
    this.uploadUrl = uploadUrl
    this.loadTheData()
  },
}
</script>

<style scoped>
.aa {width: 100%;height: 100%;background: #Fff;padding-left: 20px;box-sizing: border-box;}
.daohangtitle {height: 30px;font-size: 12px;background: #eee;margin-left: -20px;}
.sur {margin-top: 20px;position: relative;padding-left: 10px;font-size: 20px;line-height: 1;}
.sur:before {content: '';width: 5px;height: 25px;background: red;position: absolute;left: 0;top: 0;border-radius: 5px;}
.sura {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  overflow-y: auto;
  flex-wrap: wrap;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-row {
  width: 33.33%;
  padding: 10px;
  box-sizing: border-box;
}

.card-item {
  margin-top: 30rpx;
  border: lightgray solid 1rpx;
}

.box-card {
  width: 400px;
}
.text_cls{
  font-size: small;
  color: #969799;
  clear: left;
}

.el-scrollbar__wrap {
  overflow-x: hidden !important;
}
</style>